import { boolean } from "mathjs";

export interface SurgerySubType {
  code: string;
  description: string;
  numberOfCaesariansAreRelevant: boolean;
}

export interface SurgeryType {
  code: string;
  description: string;
  redosAreRelevant: boolean;
  levelsAreRelevant: boolean;
  subTypes: SurgerySubType[];
}

export class SurgerySelections
{
surgery : Surgery;
requestPatientDetails : boolean;
requestPreAnaesthesiaQuestionnaire : boolean;
requestCovidQuestionnaire : boolean;
}

export class AlchemintApplicationMetaData {
  applicationName: string = 'Alchemed';
  usesArtifacts: boolean = true;
  artifactsOfType: any = 'System.Type[]';
  rootSearcheableObject: any = 'Alchemed.DataModel.Patient';
  requiresApiKeyAuthentication: boolean = true;
  allowedCrossOrigins: any = 'System.String[]';
  useDiary: boolean = true;
  apiUrl: string = 'https://sys.alchemed.com/api';
  authUrl: string = 'https://sys.alchemed.com/user/authenticate';
  usesForms: boolean = true;
  formsOfype: any = 'System.Type[]';
  usesDataExplorer: boolean = true;
  usesBilling: boolean = true;
  usesBI: boolean = true;
  usesDocumentVersioning: boolean = true;
  companyWebSite: string = 'www.alchemed.com';
  documentationUrl: string = 'www.alchemed.com';
  licensingUrl: string = 'www.alchemed.com';
  usesWhatsAppForBusiness: boolean = false;
  usesSMTPEmail: boolean = false;
  brandColorLight: number = 785979018437;
  brandColorMedium: number = 90194316445;
  brandColorDark: number = 81604379239;
  brandForeColor: number = 0;
  dataModelAssemblyName: string = 'Alchemed.DataModel.dll';
  dataModelAssemblyNamespace: string = 'Alchemed.DataModel';
  version: string = '2.0';
}

export class TextContent {
  content: string;
  contentType: any;
}

export class SpaSettings {
  publicKey: string;
  clientVersion: string;
}

export interface IAlchemintEntity {
  readonly entityTypeName: string;
  readonly entityId: string;
  readonly entityName: string;
}

export class AccCustomerCreditNote implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AccCustomerCreditNote'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public date: Date;
  public customerAccountId: string; // POPULATE WITH CustomerAccount.Id received from API

  public accCustomerInvoiceId: string;
  public accCustomerInvoiceLineId: string;
  public patientId: string;
  public amount: number;
  public creditNoteNumber: number; // GENERATED BY API : DO NOT SUPPLY ON POST
  public creditNoteNumberViewable: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public approvedByUserId: string;
  public modDate: Date;
  public addDate: Date;
  public capturedDate: Date;
  public reason: string;
  public additionalNotes: string;

}


export class AccCustomerInvoiceHead implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AccCustomerInvoiceHead'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public date: Date;
  public customerAccountId: string; // POPULATE WITH CustomerAccount.Id received from API
  public invoiceNumber: number; // GENERATED BY API : DO NOT SUPPLY ON POST
  public invoiceNumberViewable: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public diagnosisCodes: string;
  public medicalAidDue: number;
  public taxAmount: number;
  public totalExclTax: number;
  public totalIncTax: number;
  public customerDue: number;
  public status: number;
  public billingRequestId: string;
  public approvedByUserId: string;
  public modDate: Date;
  public addDate: Date;
  public paid: boolean;
  public partPaid: boolean;
  public patientId: string;
  public venueAtInv: string;
  public authorizationCode: string;
  public mpNumberAtInv: string;
  public accountNameAtInv: string;
  public medicalAidNumberAtInv: string;
  public patientNameAtInv: string;
  public doctorNameAtInv: string;
  public referringDoctorAtInv: string;
  public chargeRateAtInv: string;
  public consultId: string;

}

export class AccCustomerInvoiceLine {
  public get entityTypeName(): string { return 'AccCustomerInvoiceLine'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public headerId: string;  // GENERATED BY API : DO NOT SUPPLY ON POST
  public lineNumber: number;  // GENERATED BY API : DO NOT SUPPLY ON POST
  public procedureCode: string;
  public procedureDescription: string;
  public materialCode: string;
  public materialDescription: string;
  public quantity: number;
  public price: number;
  public discountPercent: number;
  public totalExclTax: number;
  public taxRatePercent: number;
  public taxAmount: number;
  public customerDue: number;
  public medicalAidDue: number;
}

export class AccCustomerPayment {
  public get entityTypeName(): string { return 'AccCustomerPayment'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public customerAccountId: string; // POPULATE WITH CustomerAccount.Id received from API
  public customerAccount?: AccCustomerAccount;
  public receivedDate: Date;
  public capturedDate: Date;
  public paymentNumber: number; // GENERATED BY API : DO NOT SUPPLY ON POST
  public paymentNumberViewable: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public amount: number;
  public payMethod: number;
  public paidByPatient: boolean;
  public paidByMedicalAid: boolean;
  public addDate: Date; // GENERATED BY API : DO NOT SUPPLY ON POST
  public modifiedDate: Date; // GENERATED BY API : DO NOT SUPPLY ON POST
}

export class AccCustomerPaymentAllocation {
  public get entityTypeName(): string { return 'AccCustomerPaymentAllocation'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string; // GENERATED BY API : DO NOT SUPPLY ON POST
  public invoiceHeadId: string;
  public paymentId: string; // ADDED PY BY API from AccCustomerPayment.id  : DO NOT SUPPLY ON POST
  public allocatedAmount: number;
}


export class AccCustomerPaymentReAllocation {
  public get entityTypeName(): string { return 'AccCustomerPaymentReAllocation'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return 'AccCustomerPaymentReAllocation'; };
  public id: string;
  public paymentId: string;
  public paymentAllocationId: string;
  public fromAccountId: string;
  public toAccountId: string;
  public fromInvoiceHeaderId: string;
  public toInvoiceHeaderId: string;
  public amount: number;
  public reallocationDate: Date;
  public processedByUserId: string;
}


export class Admission implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Admission'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.description; };
  public id: string;
  public patientId: string;
  public siteId: string;
  public ward: string;
  public admissionDate: Date;
  public interimDiagnosis: string;
  public treatingDoctor: string;
  public notes: string;
  public dischargeDate: Date;
  public description: string;
  public userId: string;
  public inProgress: boolean;
  public iCD10Code1: string;
  public iCD10Code2: string;
  public iCD10Code3: string;
  public iCD10Code4: string;
  public iCD10Code5: string;
  public iCD10Code6: string;
  public comments: string;
  public other : string;
  public other2 : string;
}


export class Agreement implements IAlchemintEntity {

  public get entityTypeName(): string { return 'Agreement'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.standardFunctionalContentId; };
  public id: string;
  public patientId: string;
  public agreeDate: Date;
  public artifactId: string;
  public standardFunctionalContentId: string;
  public signatureString: string;
}


export class AmpathLastLoad implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AmpathLastLoad'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public code: string;
  public loadTime: Date;
  public fromTime: Date;
}

export class AmpathLoadedFiles implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AmpathLoadedFiles'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public artifacctId: string;
  public patientId: string;
  public loadTime: Date;
  public name: string;
  public isUpdate: boolean;
}

export class ApiKeyMasterPassword implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ApiKeyMasterPassword'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public apiKey: string;
  public masterPassword: string;
}

export class ApiKeys implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ApiKeys'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.apiKey; };
  public id: string;
  public companyName: string;
  public apiKey: string;
  public emailAddress: string;
  public ampathCode: string;
  public userCount: number;
  public useAmpath: boolean;
  public modDate: Date;
  public disabled: boolean;
  public ampathDepartmentFilter: string;
  public permissionedApiKey: string;
  public publicContentAccessKey: string;
  public useNotesChangeTracking: boolean;
  public category1: string;
  public category2: string;
  public category3: string;
  public bilingMethod: number;
  public integrateOrganisationIdentifier: string;
  public startDate: Date;
  public isDemoLicense: boolean;
  public demoLicenseExpiryDate: Date;
  public dataLakeLicensed: boolean;
  public clinicianCount: number;
  public practiceManagerCount: number;
  public readOnlyUserCount: number;
  public bereauUserCount: number;
  public locumUserCount: number;
  public locationCountry: string;
  public locationRegion: string;
  public includeAlphaFetures: boolean;
  public includeBetaFetures: boolean;
  public webAppOnlyClient: boolean;
  public licensedForTranscriptions: boolean;
  public licensedForAI: boolean;
  public addedDate: Date;
  public vendorCode: string;


  public webRequestGenerationKey: string;
  public licenseComments: string;
  public purgeRequested: boolean;
  public datePurgeRequested: Date;
  public purged: boolean;
  public purgedAt: Date;
  public comments: string;
  public clientCode: string;
  public disableIntegratedPatientTrigger: boolean;

  public setupDateUTC: Date;
  public firstInvoiceDateUTC : Date;
  public aproximateMonthlyRevenue: number;

}

export class ApplicationDesriber implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ApplicationDesriber'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public applicationName: string;
  public usesArtifacts: boolean;
  public artifactsOfType: any;
  public rootSearcheableObject: any;
  public requiresApiKeyAuthentication: boolean;
  public allowedCrossOrigins: any;
  public useDiary: boolean;
  public apiUrl: string;
  public authUrl: string;
  public usesForms: boolean;
  public formsOfype: any;
  public usesDataExplorer: boolean;
  public usesBilling: boolean;
  public usesBI: boolean;
  public usesDocumentVersioning: boolean;
  public companyWebSite: string;
  public documentationUrl: string;
  public licensingUrl: string;
  public usesWhatsAppForBusiness: boolean;
  public usesSMTPEmail: boolean;
  public brandColorLight: number;
  public brandColorMedium: number;
  public brandColorDark: number;
  public brandForeColor: number;
  public dataModelAssemblyName: string;
  public dataModelAssemblyNamespace: string;
  public version: string;
}

export class ApplicationKey implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ApplicationKey'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public appKey: string;
  public purposeId: string;
  public comments: string;
  public disabled: boolean;
  public addDate: Date;
  public modDate: Date;
  public anonomizeData: boolean;
}


export class AppointMentSlot implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AppointMentSlot'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public start: Date;
  public end: Date;
  public booked: boolean;
  public minutes: number;
}

export class Artifact implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Artifact'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  // public get parentId(): string { return this.patientId; };
  // public set parentId(value : string) { this.patientId = value; };
  public parentId: string;
  public patientId: string;
  public id: string;
  public name: string;
  public artifactTypeId: string;
  public fileExtension: string;
  public modDate: Date;
  public sha256: string;
  public fileSize: number;
  public compressedFileSize: number;
  public unCompressedFileSize: number;
  public parentType: string;
  public eventDate: Date;
  public functionalCode: string;
  public folderId: string;
  public metaData: string;
  public missing: boolean;
  public locked: boolean;

  public get nameWithoutExtension(): string {
    return this.removeExtension(this.name);
  }

  private removeExtension(filename: string): string {
    var lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    else return filename.substr(0, lastDotPosition);
  }

}

export class ArtifactChangeLog implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactChangeLog'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public loginId: string;
  public patientId: string;
  public id: string;
  public artifactId: string;
  public changeLogType: number;
  public addDate: Date;
  public artifactName: string;
}

export class ArtifactLink implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactLink'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public parentArtifactId: string;
  public childArtifactId: string;
  public secquenceNumber: number;
  public addDate: Date;
}

export class ArtifactThumbNail implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactThumbNail'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public artifactId: string;
}

export class ArtifactToFeatureMap implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactToFeatureMap'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public code: string;
  public artifactId: string;
}

export class ArtifactType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public code: string;
  public name: string;
  public disabled: boolean;
  public positionOrder: number;
  public localFolderName: string;
  public forObjectsOfType: string;
}


export class ArtifactWithFileData implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtifactWithFileData'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public artifact: any;
  public thumbNailData: any;
  public data: ArrayBuffer;
}

export class ArtiForm implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiForm'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public description: string;
  public author: string;
  public enabled: boolean;
  public canBeExtendedAfterUse: boolean;
  public templateArtifactId: string;
  public lockOnEntry: boolean;
  public supportsAttachments: string;
  public functionalCode: string;
  public previewOnCompletion: boolean;
  public storePreview: boolean;
  public addRecordToTypeWriterOnCompletion: boolean;
  public category: string;
  public subCategory: string;
  public primarySpecialityCode: string;
  public subSpeciality: string;
  public targetFolder: string;
  public smartFormStyleAttributes : string;
  public smartFormFieldStyleAttributes : string;
  public useTabPages: boolean;
  public isGeneralForm: boolean;
  public experimental: boolean;
  public pythonScript: string;
  public pythonOutputVariable: string;
  public isBillableForm: boolean;
  public isExternalUseForm: boolean;
  public isSmartForm: boolean;
  public usesScoring: boolean;
  public scoreCalculation: string;
  public inDevelopment: boolean;
  
}

export class ArtiFormFld implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiFormFld'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public artiFormId: string;
  public name: string;
  public description: string;
  public artiFormFldTypeId: string;
  public mandatory: boolean;
  public position: number;
  public pickValues: string;
  public minimumValue: number;
  public maximumValue: number;
  public defaultValue: string;
  public propertyKeyName: string;
  public isBillingField: boolean;
  public billingFieldType: string;
  public customListCode: string;
  public smartFormFieldStyleAttributes : string;
  public labelStyle: string;
  public ratingAliases: string;
  public fieldCode: string;
  public branchLogicField: string;
  public branchLogicComparator: string;
  public branchLogicCheckValue: string;

}

export class ArtiFormFldType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiFormFldType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public clrTypeName: string;
  public supportsTextEntry: boolean;
  public supportsPickValues: boolean;
  public supportsDefaults: boolean;
  public supportsMax: boolean;
  public supportsMin: boolean;
  public supportsCustomList: boolean;
  public betaFieldType: boolean;
  public supportsMandatory: boolean;
  public supportsBillingField: boolean;
}

export class ArtiFormInstance implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiFormInstance'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public artiFormId: string;
  public patientId: string;
  public artifactTypeId: string;
  public name: string;
  public modDate: Date;
  public createDate: Date;
  public sha256Stamp: string;
  public eventDate: Date;
  public locked: boolean;
  public deleted: boolean;
  public billRequested: boolean;
  public dontBill: boolean;
  public relatedConsultId: string;
  public treatingClinicianId: string;
}

export class ArtiFormInstanceAttachments implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiFormInstanceAttachments'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public artiFormInstanceId: string;
  public name: string;
  public link: string;
  public fileExtension: string;
  public modDate: Date;
  public sha256: string;
  public fileSize: number;
  public unCompressedFileSize: number;
}

export class ArtiFormInstanceChangeLog implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ArtiFormInstanceChangeLog'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public artiformInstancetId: string;
  public loginId: string;
  public changeLogType: number;
  public addDate: Date;
}

export class ArtiFormState
{
    public artiFormId: string;
    public used: boolean;
    public instanceCount: number;
    public templateIsExlusiveToThisForm: boolean;
    public isBroken: boolean;
    public brokenFieldIds: string [];
    public storageStructureExists: boolean;
    public duplicateFields: boolean;
    public duplicateFieldList: string [];
    public missingCustomLists: string[];
    public isMissingCustomLists: boolean;
    public suspectPreviewSettings: boolean;

    public templateHasMissingFormFields: boolean;
    public templateMissingFormFieldsList: string [];

    public recommendations:string[];
    public usesLegacyWordTemplate: boolean;
    public billingFormButNoBillingFields: boolean;

    public formTemplateMissing: boolean;
    public formTemplateName: string;
  }

export class AutoEmailRecord implements IAlchemintEntity {
  public get entityTypeName(): string { return 'AutoEmailRecord'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public toEmail: string;
  public subject: string;
  public sentAt: Date;
  public cCEmail: string;
  public emailType: number;
  public linkId: string;
  public fromEmail: string;
}

export class BillingInstruction implements IAlchemintEntity {
  public get entityTypeName(): string { return 'BillingInstruction'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public originationId: string
  public customerId: string;
  public instructionDetails: string
  public sent: boolean;
  public acknowledged: boolean;
  public billingLinkId: string
  public sourceFormInstanceId: string
  public consultId: string
  public diagnosisCodes: string
  public procedureCodes: string
  public materialCodes: string
  public workFlowStartFlagged: boolean;
  public disabled: boolean;
  public workFlowPaused: boolean;
  public workFlowPausedReason: string
  public workflowCompleted: boolean;
}

export class BillingScedule implements IAlchemintEntity {
  public get entityTypeName(): string { return 'BillingScedule'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public procedureCodeId: string;
  public operationId: string;
  public date: Date;
  public billed: boolean;
}

export class BiographicalDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'BiographicalDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public idNumber: string;
  public homeLanguage: string;
  public dateOfBirth: Date;
  public sex: string;
  public occupation: string;
  public maritalStatus: string;
  public medicalAidDependantNumber: string;
  public nextOfKinName: string;
  public relationToPatient: string;
  public nextOfKinCell: string;
  public modDate: Date;
}

export class ClientException implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ClientException'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public loginId: string;
  public codeArea: string;
  public additionInfo: string;
  public modDate: Date;
}

export class ClipBoardInstruction implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ClipBoardInstruction'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public date: Date;
  public content: string;
  public unformattedContent: string;
  public complete: boolean;
}

export class CompletePatientDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CompletePatientDetails'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public patient: any;
  public biographicalDetails: any;
  public contactDetails: any;
  public personResponsibleForAccount: any;
  public isMainMember: boolean;
  public mainMember: any;
}

export class Consult implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Consult'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.description; };
  public id: string;
  public patientId: string;
  public doctorId: string;
  public date: Date;
  public consultTypeId: string;
  public userId: string;
  public category: string;
  public startHour: number;
  public startMinute: number;
  public endHour: number;
  public endMinute: number;
  public description: string;
  public descriptionBuiltFromPatient: string;
  public patientFullName: string;
  public notes: string;
  public siteId: string;
  public status: string;
  public commencementHour: number;
  public commencementMinute: number;
  public completionHour: number;
  public completionMinute: number;
  public icD10Code1: string;
  public icD10Code2: string;
  public icD10Code3: string;
  public icD10Code4: string;
  public icD10Code5: string;
  public icD10Code6: string;
  public isGeneralAppointment: boolean;

  public get startTimeString(): string {
    var startTime: string = this.startHour?.toString() ?? "00";
    var minutes: string = "00" + this.startMinute ?? "00";
    minutes = minutes.substring(minutes.length - 2);
    startTime += ":" + minutes;
    return startTime;
  };
  public billRequested: boolean;
  public dontBill: boolean;

  public integratedConsultTypeId: string;
  public integratedConsultTypeDescription: string;
  public integratedSiteDescription: string;
  public integratedSiteId: string;

  public patientDetailsCompleted: boolean;

  public invoiced: boolean;
  public invoiceHeaderId: string;

}

export class ConsultType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ConsultType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public description: string;
  public budgetAmount: number;
  public color: string;
  public webAppColor: string;
}

export class Contact implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Contact'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this?.firstName + ' ' + this?.lastName; };
  public id: string;
  public contactType: string;
  public title: string;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public email: string;
  public workNumber: string;
  public cellNumber: string;
  public canWhatsApp: boolean;
  public canSms: boolean;
  public canEmail: boolean;
  public notes: string;
}


export class ContactDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ContactDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public telephoneCell: string;
  public telephoneHome: string;
  public telephoneWork: string;
  public emailAddress: string;
  public address: string;
  public addressLine2: string;
  public suburb: string;
  public town: string;
  public postalCode: string;

  public postalAddress: string;
  public postalAddress2: string;
  public postalAddressSuburb: string;
  public postalAddressTown: string;
  public postalAddressPostalCode: string;
  public modDate: Date;
}

export class CustomShortcutKeys implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CustomShortcutKeys'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public shortCutKey: string;
  public name: string;
  public content: string;
  public needsShiftKeyAsWell: boolean;
}

export class CustomList implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CustomList'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public code: string;
  public name: string;
  public category: string;
}

export class CustomListValue implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CustomListValue'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public customListId: string;
  public name: string;
}

export class CustomStyleElement implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CustomStyleElement'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public style: string;
}

export class AccCustomerAccount implements IAlchemintEntity {
  public get entityTypeName(): string { return 'CustomerAccount'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.accountNumberViewable?.toString(); };
  public id: string;
  public parentId: string;
  public number: number;
  public accountNumberViewable: string;
  public name: string;
  public status: string;
}


export class DiaryConfig implements IAlchemintEntity {
  public get entityTypeName(): string { return 'DiaryConfig'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public logonId: string;
  public startHourMonday: number;
  public startMinuteMonday: number;
  public endHourMonday: number;
  public endMinuteMonday: number;
  public startHourTuesday: number;
  public startMinuteTuesday: number;
  public endHourTuesday: number;
  public endMinuteTuesday: number;
  public startHourWednesday: number;
  public startMinuteWednesday: number;
  public endHourWednesday: number;
  public endMinuteWednesday: number;
  public startHourThursday: number;
  public startMinuteThursday: number;
  public endHourThursday: number;
  public endMinuteThursday: number;
  public startHourFriday: number;
  public startMinuteFriday: number;
  public endHourFriday: number;
  public endMinuteFriday: number;
  public startHourSaturday: number;
  public startMinuteSaturday: number;
  public endHourSaturday: number;
  public endMinuteSaturday: number;
  public startHourSunday: number;
  public startMinuteSunday: number;
  public endHourSunday: number;
  public endMinuteSunday: number;
  public defaultAppointmentLength: number;
  public defaultAppointmentColor: number;
  public defaultAppointmentWebColor: string;
  public showNotes: boolean;
  public hideWeekends: boolean;


}

export class Doctor implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Doctor'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public practiceNo: string;
  public lastName: string;
  public firstName: string;
  public email: string;
}

export class ExternalRequest implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ExternalRequest'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public requestType: number;
  public status: string;
  public creationDate: Date;
  public completed: boolean;
  public completedDate: Date;
  public expireDate: Date;
  public requestKey: string;
  public artifactList: string;
  public artiFormList: string;
  public additionalData: string;

  public readOnly: boolean;
  public surgeryId: string;

  public disabled: boolean;
  public workFlowPaused: boolean;
  public workFlowPausedReason: string;
  public workflowCompleted: boolean;
  public workFlowStartFlagged: boolean;
  public processessSelf: boolean;
  public initiatingLoginId: string;
  public shareToContactId: string;

}

export class Folder implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Folder'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public parentId: string;
  public name: string;
}

export class Habit implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Habit'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public userId: string;
  public isBadHabit?: boolean | null;
  public type: string;
}

export class HabitEntry implements IAlchemintEntity {
  public get entityTypeName(): string { return 'HabitEntry'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.habitId; };
  public id: string;
  public habitId: string;
  public userId: string;
  public dateString: string; // dd-mm-yyyy
  public amount: number;
  public checked: boolean;

}


export class ICD10Code implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ICD10Code'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public description: string;
}

export class Invoice implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Invoice'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public number: number;
  public customerName: string;
  public reference: string;
  public parentId: string;
  public invoiceTypeId: string;
  public modDate: Date;
  public invoiceDate: Date;
}

export class InvoiceLine implements IAlchemintEntity {
  public get entityTypeName(): string { return 'InvoiceLine'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.description; };
  public id: string;
  public invoiceId: string;
  public description: string;
  public itemCode: string;
  public quantity: number;
  public unitPrice: number;
  public discount: number;
  public taxRate: number;
  public invoiceLineNumber: number;
  public modDate: Date;
}

export class InvoiceType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'InvoiceType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public code: string;
  public name: string;
}
export class IntegratedPatientAppointmentLink implements IAlchemintEntity
{
  public get entityTypeName(): string { return 'IntegratedPatientAppointmentLink'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.id; };
  public id : string
  public patientId : string;
  public integratedPatientId : string;
  public integratedAppointmentId: string;
  public compositeRequestId: string
  public addedDate: Date;

}
export class IntegratedUserMap
{
    public id: string;
    public loginId: string;
    public integratedIdentifier: string;
}

export class Journal implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Journal'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public journalDate: Date;
  public transactionType: string;
  public amount: number;
  public reference: string;
  public narrative: string;
  public reason: string;
  public number: number;
}

export class Login implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Login'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.userName; };
  public id: string;
  public userName: string;
  public passwordHash: string;
  public token: string;
  public masterPriveledges: boolean;
  public deleted: boolean;
  public isAdminStaff: boolean;
  public emailAddress: string;
  public readOnlyLogon: boolean;
  public isLocum: boolean;
  public isBereauUser: boolean;
  public integratedPracticeNumber: string;
  public enable2FA: boolean;
  public languageToUseCode: string;
  public cellPhoneNumber: string;
  public latestTemsAndConditionsVersionAgreedTo: number;
  public integratedPractitionerId: string;
}

export class LoginRefreshToken implements IAlchemintEntity {
  public get entityTypeName(): string { return 'LoginRefreshToken'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public loginId: string;
  public refreshTokenObject: string;
  public tokenString: string;
  public expiresAt: Date;
}

export class MasterKey implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MasterKey'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public password: string;
}
export class MaterialCode implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MaterialCode'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public description: string;
  public units: number;
  public disabled: boolean;
}

export class MedicalAidDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MedicalAidDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public mainMemberId: string;
  public dependantNumber: string;
  public mainMemberName: string;
  public mainMemberTelephoneCell: string;
  public mainMemberTelephoneHome: string;
  public mainMemberTelephoneWork: string;
  public mainMemberEmailAddress: string;
  public mainMemberAddress: string;
  public mainMemberPostalCode: string;
  public occupation: string;
  public employer: string;
  public medicalAidName: string;
  public medicalAidNumber: string;
  public gapCover: boolean;
  public referringDoctor: string;
  public modDate: Date;
}

export class MedicalConditions implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MedicalConditions'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public icdCode1Id: string;
  public icdCode2Id: string;
  public icdCode3Id: string;
  public icdCode4Id: string;
  public icdCode5Id: string;
  public icdCode6Id: string;
  public comments: string;
  public modDate: Date;
}

export class MedicalInvoice implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MedicalInvoice'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public number: number;
  public customerName: string;
  public reference: string;
  public parentId: string;
  public dependantId: string;
  public invoiceTypeId: string;
  public modDate: Date;
  public invoiceDate: Date;
  public dueDate: Date;
  public subtotal: number;
  public discount: number;
  public postDiscount: number;
  public tax: number;
  public taxRate: number;
  public total: number;
  public transactionType: string;
}

export class MedicalInvoiceLine implements IAlchemintEntity {
  public get entityTypeName(): string { return 'MedicalInvoiceLine'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.description; };
  public id: string;
  public parentId: string;
  public dependantId: string;
  public dependantName: string;
  public invoiceId: string;
  public invoiceLineNumber: number;
  public procedureCodeId: string;
  public iCD10Codes: string;
  public description: string;
  public itemCode: string;
  public quantity: number;
  public unitPrice: number;
  public preModifiedAmount: number;
  public modifiedPercentage: number;
  public modifiedAmount: number;
  public discount: number;
  public discountAmount: number;
  public discountedTotal: number;
  public taxRate: number;
  public postTaxTotal: number;
  public transactionType: string;
  public modDate: Date;
  public taxAmount: number;
  public modifiable: boolean;
  public reason: string;
  public procedureDate: Date;
}

export class MedicalSpeciality
{
  constructor(public code: string,  public name: string)
  {

  }
}

export class MessagingPayAsYouGoBalance
{
    public id: string;
    public code: string;
    public modDate: Date;
    public balance: number;
}

export class MessagingPayAsYouGoBalancePurchase
{
  public id: string;
  public code: string;
  public modDate: Date;
  public amount: number;
}


export class Note implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Note'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public patientId: string;
  public noteTypeId: string;
  public date: Date;
  public content: string;public name: string;
}

export class NoteType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'NoteType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public code: string;
  public name: string;
  public forObjectsOfType: string;
}

export class NoteItemType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'NoteItemType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public code: string;
  public fileFormat: string;
  public anticipatedSize: string;
  public templateArtifactId: string;
  public styleHint: string;
  public disabled: boolean;
  public layoutPosition: number;
  public textTemplate: string;
  public computed: boolean;
}


export class OnlineBooking implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OnlineBooking'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public doctorId: string;
  public date: Date; 
  public durationMinutes: number;
  public verifiedByEmail: boolean;
}



export class OperationDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OperationDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public date: Date;
  public surgeonId: string;
  public assistant: string;
  public anaesthetist: string;
  public operationTypeId: string;
  public operationName: string;
  public position: string;
  public side: string;
  public technique: string;
  public findings: string;
  public woundClosure: string;
  public postOp: string;
}

export class Organisation implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Organisation'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public code: string;
  public registrationNumber: string;
  public medicalPracticeNumber: string;
  public practiceNumber: string;
  public description: string;
  public additional: string;
  public telephone: string;
  public fax: string;
  public addressLine1: string;
  public addressLine2: string;
  public addressLine3: string;
  public postalCode: string;
  public vatNumber: string;
  public taxPercent: number;
  public postalAddressLine1: string;
  public postalAddressLine2: string;
  public postalAddressLine3: string;
  public postalAddressPostalCode: string;
  public website: string;
  public email: string;
  public telephoneNumber: string;
  public bankName: string;
  public branch: string;
  public branchCode: string;
  public bankAddress: string;
  public accountName: string;
  public accountNumber: string;
  public swiftCode: string;
  public paymentTermsDays: number;
  public specialityCode: string;
}

export class OrgSetting implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OrgSetting'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public settingName: string;
  public settingValue: string;
}

export class OrgStats implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OrgStats'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public orgIdentifier: string;
  public orgName: string;
  public takenTime: Date;
  public year: number;
  public month: number;
  public day: number;
  public spaceUsedMb: number;
  public spaceUsedCompressedMb: number;
  public spaceUsedGb: number;
  public spaceUsedCompressedGb: number;
  public spaceUsedByDatabaseMb: number;
  public files: number;
  public patients: number;
  public consults: number;
  public scripts: number;
  public formInstances: number;
  public patientDetailCompletions: number;
  public patientDetailCompletionRequests: number;
  public patientAgreements: number;
  public mirrorFileCount: number;
  public missingMirrorFileCount: number;
  public clinicalNotes: number;
  public images: number;
  public pdfs: number;
  public fileAddsLastMonth: number;
  public fileUpdatesLastMonth: number;
  public fileDeletesLastMonth: number;
  public scriptsLastMonth: number;
  public ampathFileCount: number;
  public ampathFilesLoadedThisMonth: number;
  public lastFileUpdate: Date;
  public licensedUserCount: number;
  public users: number;
  public usesAmpath: boolean;
  public useNotesChangeTracking: boolean;
  public filesRequireLink: number;
  public category1: string;
  public category2: string;
  public category3: string;
  public get vsi(): number {
    return 8;
  }

}

export class OrgStatsDelete implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OrgStatsDelete'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public orgIdentifier: string;
  public orgName: string;
  public takenTime: Date;
  public year: number;
  public month: number;
  public day: number;
  public spaceUsedGb: number;
  public spaceUsedCompressedGb: number;
  public files: number;
  public patients: number;
  public consults: number;
  public scripts: number;
  public formInstances: number;
  public patientDetailCompletions: number;
  public patientDetailCompletionRequests: number;
  public clinicalNotes: number;
  public images: number;
  public pdfs: number;
  public fileAddsLastMonth: number;
  public fileUpdatesLastMonth: number;
  public fileDeletesLastMonth: number;
  public scriptsLastMonth: number;
  public ampathFileCount: number;
  public ampathFilesLoadedThisMonth: number;
  public lastFileUpdate: Date;
  public licensedUserCount: number;
  public users: number;
  public usesAmpath: boolean;
  public useNotesChangeTracking: boolean;
  public filesRequireLink: number;
}

export class OrgSupportTicket
{
  public get entityTypeName(): string { return 'OrgSupportTicket'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.id; };
  public id : string ;
  public supportTicketId:string;
  public sequenceNumber: number;
  public addedDate: Date;
  public question: string;
}


export class OutcomeCode implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OutcomeCode'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.code; };
  public id: string;
  public code: string;
  public description: string;
}

export class Patient implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Patient'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.firstName + ' ' + this.lastName + ', ' + this.patientNo;;; };
  public id: string;
  public title: string;
  public firstName: string;
  public patientName: string;
  public lastName: string;
  public name: string;
  public patientNo: string;
  public modDate: Date;
  public deleted: boolean;
  public addDate: Date;
  public termsAndConditionsAccepted: boolean;
  public dateAccepted: Date;
  public comments: string;
  public sequenceNumber: number;
}

export enum ePatientNoteTyep
{
    eClinical,
    eAdministrative
}
export class PatientNote implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PatientNote'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public admissionId: string;
  public consultId: string;
  public surgeryId: string;
  public userId: string;
  public date: Date;
  public artifactId: string;
  public artiFormInstanceId
  public autoGenerated: boolean;
  public type: ePatientNoteTyep;
}


export class PatientEvent implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PatientEvent'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public date: Date;
  public description: string;
}

export class PatientReferal implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PatientReferal'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public doctorId: string;
  public date: Date;
}

export class Payment implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Payment'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public paymentDate: Date;
  public transactionType: string;
  public amount: number;
  public reference: string;
  public narrative: string;
  public number: number;
}

export class PerfStats implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PerfStats'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
  public dataModelAssemblyCallCount: number;
  public entityExistsCallCount: number;
  public delegatedObjectCallCount: number;
  public getCount: number;
  public postCount: number;
  public putCount: number;
  public deleteCount: number;
  public getCountDelta: number;
  public postCountDelta: number;
  public putCountDelta: number;
  public deleteCountDelta: number;
  public memoryUsed: number;
  public memoryUsedAfterCollect: number;
  public memoryFreedByCollect: number;
  public memoryManagedAndUnmanaged: number;
  public memoryPhysicalUsage: number;
  public takenTime: Date;
}

export class PersonalAssistant implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PersonalAssistant'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public practiceNo: string;
  public lastName: string;
  public firstName: string;
  public email: string;
}

export class PersonResponsibleForAccount implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PersonResponsibleForAccount'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public mainMemberId: string;
  public dependantNumber: string;
  public title: string;
  public name: string;
  public firstName: string;
  public lastName: string;
  public idNumber: string;
  public telephoneCell: string;
  public telephoneHome: string;
  public telephoneWork: string;
  public emailAddress: string;
  public address: string;
  public address2: string;
  public suburb: string;
  public town: string;
  public postalCode: string;

  public postalAddress1: string;
  public postalAddress2: string;
  public postalAddressSuburb: string;
  public postalAddressTown: string;
  public postalAddressPostalCode: string;

  public occupation: string;
  public employer: string;
  public medicalAidName: string;
  public medicalAidNumber: string;
  public medicalAidPlanOptionCode: string;
  public medicalAidPlanSubOptionCode: string;
  public gapCover: boolean;
  public referringDoctor: string;
  public modDate: Date;
}

export class PreAnaesthesia implements IAlchemintEntity {
  public get entityTypeName(): string { return 'PreAnaesthesia'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public externalRequestId: string;
  public submitDate: Date;
  public height: number;
  public weight: number;
  public comments: string;
  public completed: boolean;
  public modDate: Date;
}

export class ProcedureCode implements IAlchemintEntity {
  public get entityTypeName(): string { return 'ProcedureCode'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public description: string;
  public units: number;
  public modifiable: boolean;
}

export class SeedData implements IAlchemintEntity {
  public get entityTypeName(): string { return 'SeedData'; };
  public get entityId(): string { return ''; };
  public get entityName(): string { return ''; };
}

export class Site implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Site'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public description: string;
}

export class SourceControlRepo  implements IAlchemintEntity {
  public get entityTypeName(): string { return 'SourceControlRepo'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
}

export class SourceControlFile  implements IAlchemintEntity {
  public get entityTypeName(): string { return 'SourceControlFile'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public repositoryId: string;
  public name: string;
  public folder: string;
}

export class SourceControlCommit  implements IAlchemintEntity {
  public get entityTypeName(): string { return 'SourceControlFile'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.version?.toString(); };
  public id: string;
  public sourceControlFileId: string;
  public version: number;
  public commitMessage: string;
  public modDateUTC: Date;
}



export class SupportDeskUser
{
  public get entityTypeName(): string { return 'SupportDeskUser'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public lineLevel: string;
  public userDisabled: boolean;
  public password: string;
  public team: string;
  public resellerFilters : string
  public vendorFilters : string;
  public canAddClients: boolean;
  public canEditClients: boolean;
  public canUseSupportDesk: boolean;
  public canViewReports: boolean;
  public jwtClaimsToken: string;
  public canViewRegisteredPeople: boolean;
  public canUseAIStudioCode: boolean;
  public linkSent: boolean;
  public cellNumber: string;
  public passwordSent: boolean
}

export class SupportTicket 
{
    public get entityTypeName(): string { return 'SupportTicket'; };
    public get entityId(): string { return this.id; };
    public get entityName(): string { return this.description; };
    public id: string;
    public description: string;
    public customerId: string;
    public loggedDate: Date;
    public resolutionDate: Date;
    public resolution: string;
    public details: string;
    public ticketNumber: number;
    public closed: boolean;
    public loggedBy: string;
    public type: string;
    public priority: string;
    public loggedByUserId: string;
    public assignedToSupportDeskUserId: string;
    public acknowledgedBySupportDeskUserId: string;
    public aknowledgedUTCDateTime: Date;
    public tshirtSize: string;

}

export class SupportTicketChatItem {
  public get entityTypeName(): string { return 'SupportTicketChatItem'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.message; };
  public id: string;
  public supportTicketId: string;
  public messageSequenceNumber: number;
  public message: string;
  public userId: string;
  public addedDate: Date;
  public isClientMessage: boolean;
  public routeLink: string;
}


export class Surgery implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Surgery'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public patientId: string;
  public surgeonId: string;
  public anaethetistId: string;
  public surgeryDate: Date;
  public surgeryType: string;
  public surgerySubType: string;
  public moreThanTwoLevels: boolean;
  public isRedo: boolean;
  public noOfPreviousCaesarians: number;
  public procedure: string;
  public patientRequestLink: string;
  public anaethetistRequestLink: string;
  public surgeonRequestLink: string;
  public startedWorkFlowPat: boolean;
  public startedWorkFlowAen: boolean;
  public startedWorkFlowSurg: boolean;
  public workFlowStartFlagged: boolean;
  public startedWorkFlowPatTime: Date;
  public startedWorkFlowAenTime: Date;
  public startedWorkFlowSurgTime: Date;
  public preAnaestheticFormInstId: string;
  public preAnaestheticArtiformFormId: string;
  public covidFormInstId: string;
  public covidArtiformFormId: string;
  public additionalFormInstIds: string;
  public additionalArtiformFormIds: string;
  public disabled: boolean;
  public workFlowPaused: boolean;
  public workFlowPausedReason: string;
  public createdDate: Date;
  public modDate: Date;
  public covidResultsRequestLink: string;
  public startedWorkDeliverCovidReportTime: Date;
  public shareClinicalNotes: boolean;
  public startHour: number;
  public startMinute: number;
  public endHour: number;
  public endMinute: number;
  public userId: string;
  public workflowCompleted: boolean;
}

export class SurgicalEventDetails implements IAlchemintEntity {
  public get entityTypeName(): string { return 'SurgicalEventDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.id; };
  public id: string;
  public patientId: string;
  public consultId: string;
  public surgeon: string;
  public assistant: string;
  public anaesthetist: string;
  public anaestheticType: string;
  public startTime: string; // Representing time in HH:MM format
  public finishTime: string; // Representing time in HH:MM format
  public tourniquetTime?: number; // In minutes, optional
  public operativeProcedure: string;
  public side: string;
  public operativeFindings: string;
}

export  class SurgicalEventArtifacts implements IAlchemintEntity 
{
    public get entityTypeName(): string { return 'SurgicalEventArtifacts'; };
    public get entityId(): string { return this.id; };
    public get entityName(): string { return this.id; };
    public id :string;
    public surgicalEventDetailsId : string;
    public artifactId : string;
    public type : string;
    public metaData : string;
}

export class TermsAndConditions
{
  public get entityTypeName(): string { return 'TimeSaverItem'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public versionNumber: number;
  public htmlContent: string;
  public addedDate: Date;
  public modDate: Date;
  public regionCode: string;
}


export class TimeSaverItem {
  public get entityTypeName(): string { return 'TimeSaverItem'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public key: string;
  public name: string;
  public timeSaveMinsPerUse: number;
  public usageCountPerWeek: number;
  public loginId: string;
}

export class TariffType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TariffType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
}
export class TaskBoard implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TaskBoard'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public ownerUserId: string;
  public name: string;
  
}
export class TaskList implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TaskList'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public taskBoardId: string;
  public name: string;
  public color: string;
  public showMenu: boolean;
}

export class TaskType implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TaskType'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public code: string;
  public name: string;
}

export class TaxRate implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TaxRate'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public value: number;
}


export const TheatreSlateArtifactTypeId: string = '-7';
export const MemoArtifactId: string = 'MASTER_CLIPBOARD';

export class TheatreSlate implements IAlchemintEntity {
  public get entityTypeName(): string { return 'TheatreSlate'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public userId: string;
  public date: Date;
  public slateNumber: number;
  public siteId: string;
  public artifactId: string;
}

export class Triage implements IAlchemintEntity {
  public get entityTypeName(): string { return 'Triage'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public date: Date;
  public loginId: string;
  public icuBedsTotal: number;
  public icuBedsAvailable: number;
  public highCareBedsTotal: number;
  public highCareBedsAvailable: number;
  public ventilatorsTotal: number;
  public ventilatorsAvailable: number;
  public operatingTheatreTotal: number;
  public operatingTheatreAvailable: number;
  public isolationCubiclesTotal: number;
  public isolationCubiclesAvailable: number;
  public noOfCovidDeaths: number;
  public noOfCovidDischarged: number;
  public noOfCovid19Cases: number;
  public noOfCovid19CasesInWard: number;
  public noOfCovid19CasesInHighCare: number;
  public noOfCovid19CasesInICU: number;
  public noOfCovid19CasesOnEcmo: number;
  public noOfCovid19CasesVentilated: number;
  public noOfPUICases: number;
  public noOfPUICasesInWard: number;
  public noOfPUICasesInHighCare: number;
  public noOfPUICasesInICU: number;
  public noOfPUICasesOnECMO: number;
  public noOfPUICasesVentilated: number;
}

export class UnitValue implements IAlchemintEntity {
  public get entityTypeName(): string { return 'UnitValue'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return ''; };
  public id: string;
  public name: string;
  public value: number;
}

export class UserCustomConfiguration implements IAlchemintEntity{
  public get entityTypeName(): string { return 'UserCustomConfiguration'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.id; };

  public id: string;
  public loginId: string;
  public orgLogoArtifactId: string;
  public letterTemplateArtifactId: string;
  public scriptArtiformId: string;
  public sickNoteArtiformId: string;
  public emailSigTemplateArtifactId: string;
  public medicalLensCustomListId: string;
  
}

export class UserFeatureEvaluations
{
  public get entityTypeName(): string { return 'UserFeatureEvaluations'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.id; };
  public id: string
  public featureId: string;
  public userLoginId: string;
  public percentageRating: number;
  public featureCode: string;
}

export class UserTask implements IAlchemintEntity {
  public get entityTypeName(): string { return 'UserTask'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public patientId: string;
  public taskTypeId: string;
  public date: Date;
  public completed: boolean;
  public completedDate: Date;
  public name: string;
  public details: string;
  public userId: string;
  public priority: string;
  public parentTaskId: string;
  public addedById : string;
  public addedDate: Date;
  public reminder : boolean;
  public reminderDate : Date;
  public childTasks : string
  public taskListId : string


  public showMenu: boolean;

}

export class UserTermsAndConditionsAgreedTo
{
  public get entityTypeName(): string { return 'OrgSmtpDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return 'OrgSmtpDetails'; };
  public id : string;
  public loginId: string;
  public versionNumber : number;
  public termsAndConditionsId: string;
  public acceptedDate: Date;
}

export class  OrgSmtpDetails  implements IAlchemintEntity {
  public get entityTypeName(): string { return 'OrgSmtpDetails'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return 'OrgSmtpDetails'; };
  id: string;
  loginId?: any;
  smtpServer: string;
  userName: string;
  password: string;
  port: number;
  enableSsl: boolean;
  useForPreAnaestheticProcess:boolean;
}

export interface ContactType {
  id: string;
  name: string;
  surgeon: boolean;
}


export class WebAppItem implements IAlchemintEntity {
  public get entityTypeName(): string { return 'WebAppItem'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public itemType: string;
  public data: string;
}

export class XLReport implements IAlchemintEntity {
  public get entityTypeName(): string { return 'XLReport'; };
  public get entityId(): string { return this.id; };
  public get entityName(): string { return this.name; };
  public id: string;
  public name: string;
  public templateArtifactId: string;
  public containerArtifactId: string;
  public dataRangeTarget: string;
  public paramaterDefinitions: string;
}

export class FileCreationDetails
{
  public directory: string;
  public name: string;
  public contents: string;
}

export class FileInsertionInstruction
{
  public directory: string;
  public name: string;
  public contents: string;
  public insertionPoint: string;
 
}